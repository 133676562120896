import { useContext } from "react";
import { Link } from "react-router-dom";

import { Container } from "@mui/material";
import { Menu, rem, Button, Text, Burger } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import Logo from "../../Assets/IMG/FullLogo.svg";

import {
  IconDeviceDesktopAnalytics,
  IconHelpCircle,
  IconLogout,
  IconMailbox,
  IconNews,
  IconUsersGroup,
} from "@tabler/icons-react";
import "./styles.scss";
import { AuthContext } from "../../Pages/Dashboard";
export const links = [
  { label: "Team", link: "team", icon: IconUsersGroup },
  { label: "Blog & Articles", link: "blog", icon: IconNews },
  { label: "Responses", link: "responses", icon: IconMailbox },
  { label: "Edit Stats", link: "stats", icon: IconDeviceDesktopAnalytics },
  { label: "FAQs", link: "faq", icon: IconHelpCircle },
];
export default function Navbar() {
  const authContext = useContext(AuthContext);
  const [
    isMenuOpened,
    { toggle: ToggleMenu, open: OpenMenu, close: CloseMenu },
  ] = useDisclosure(false);
  return (
    <div className="navbar-container flex-row align-center justify-center">
      <Container maxWidth="lg">
        <nav className="navbar flex-row align-center justify-between width-100">
          <Link
            to="/dashboard"
            className="logo-container flex-row align-center justify-center"
          >
            <img src={Logo} alt="" className="logo" />
          </Link>
          <Menu
            shadow="md"
            width={240}
            opened={isMenuOpened}
            onClose={CloseMenu}
          >
            <Menu.Target>
              <Burger
                opened={isMenuOpened}
                onClick={ToggleMenu}
                aria-label="Toggle Menu"
              />
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>
                {authContext?.profile?.firstName}{" "}
                {authContext?.profile?.lastName}
              </Menu.Label>
              {links.map((link) => {
                return (
                  <Link to={`/dashboard/${link.link}`}>
                    <Menu.Item
                      key={link.label}
                      leftSection={
                        <link.icon size={18} color="var(--blue-primary)" />
                      }
                      my={5}
                    >
                      <Text fz={16} fw={500} c="var(--darkest-green)">
                        {link.label}
                      </Text>
                    </Menu.Item>
                  </Link>
                );
              })}

              <Menu.Divider />

              <Menu.Label>Terminate Session</Menu.Label>
              <Menu.Item
                color="red"
                leftSection={<IconLogout size={18} />}
                onClick={() => {
                  authContext?.logout();
                }}
              >
                <Text fz={16} fw={500}>
                  Logout
                </Text>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </nav>
      </Container>
    </div>
  );
}
