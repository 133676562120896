import { Link } from "react-router-dom";

import { Container, Title, Text, Button, Group } from "@mantine/core";
import { Illustration } from "./Illustration";
import "./styles.scss";
export default function NothingHere404() {
  return (
    <Container className="nothing-here-container">
      <div className="inner">
        <Illustration className="image" />
        <div className="content">
          <Title className="title">Nothing to see here</Title>
          <Text c="dimmed" size="lg" ta="center" className="description">
            This page does not exist. You must have clicked an invalid URL.
          </Text>
          <Group justify="center">
            <Link to="/dashboard">
              <Button size="md">Back to Dashboard</Button>
            </Link>
          </Group>
        </div>
      </div>
    </Container>
  );
}
