import React, { useEffect } from "react";

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Aos from "aos";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Loader, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";

import "aos/dist/aos.css";
import "./index.scss";
import "ckeditor5/ckeditor5.css";
import Login from "./Pages/Auth/Login";
import Dashboard from "./Pages/Dashboard";
import CreatePost from "./Pages/CreatePost";
import Overview from "./Pages/Overview";
import Team from "./Pages/Team";
import Responses from "./Pages/Responses";
import Stats from "./Pages/Stats";
import FrequentlyAskedQuestions from "./Pages/FAQs";
import { usePerformRequest } from "./Lib/usePerformRequest";
import { Endpoints } from "./Lib/Endpoints";
import NothingHere404 from "./Pages/NothingHere404";

import SkeletonLoader from "./Components/Loader";
import Blog from "./Pages/Blog";
import UpdatePost from "./Pages/UpdatePost";
import { ForgotPassword } from "./Pages/Auth/ForgotPassword";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <></>;
}
function RedirectWhereNecessary() {
  const navigate = useNavigate();
  const { isLoading, response } = usePerformRequest({
    method: "GET",
    url: Endpoints.GetProfile,
  });
  useEffect(() => {
    if (!isLoading && response) {
      if (response?.status === 200) {
        navigate("/dashboard");
      } else {
        navigate("/login");
      }
    }
  }, [isLoading]);
  return (
    <>
      <div
        className="width-100 flex-row align-center justify-center"
        style={{
          height: "100vh",
          overflow: "clip",
        }}
      >
        <Loader size={200} />
      </div>
    </>
  );
}
const router = createBrowserRouter([
  {
    path: "*",
    element: <NothingHere404 />,
    loader: SkeletonLoader,
  },
  {
    path: "/",
    element: <RedirectWhereNecessary />,
    loader: SkeletonLoader,
  },
  {
    path: "/login",
    element: <Login />,
    loader: SkeletonLoader,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    loader: SkeletonLoader,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    loader: SkeletonLoader,
    children: [
      {
        path: "/dashboard/team",
        element: <Team />,
        loader: SkeletonLoader,
      },
      {
        path: "/dashboard/blog",
        element: <Blog />,
        loader: SkeletonLoader,
      },
      {
        path: "/dashboard/responses",
        element: <Responses />,
        loader: SkeletonLoader,
      },
      {
        path: "/dashboard/stats",
        element: <Stats />,
        loader: SkeletonLoader,
      },
      {
        path: "/dashboard/faq",
        element: <FrequentlyAskedQuestions />,
        loader: SkeletonLoader,
      },
      {
        path: "/dashboard/create-post",
        element: <CreatePost />,
        loader: SkeletonLoader,
      },
      {
        path: "/dashboard/post/:postID",
        element: <UpdatePost />,
        loader: SkeletonLoader,
      },
    ],
  },
]);
const App: React.FC = () => {
  Aos.init();
  return (
    <>
      <MantineProvider
        theme={{
          components: {
            Loader: Loader.extend({
              defaultProps: {
                type: "bars",
              },
            }),
          },
        }}
      >
        <Notifications />
        {/* <ScrollToTop /> */}

        <RouterProvider router={router} />
      </MantineProvider>
    </>
  );
};
export default App;
