// @ts-nocheck
import React, { useRef, useState, useEffect } from "react";

import { useBeforeUnload, useBlocker, useParams } from "react-router-dom";
import { Container } from "@mui/material";

import {
  Text,
  TextInput,
  Button,
  Switch,
  rem,
  Tooltip,
  Notification,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  IconCamera,
  IconCheck,
  IconEye,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import { addClass, removeClass, Browser } from "@syncfusion/ej2-base";
import {
  RichTextEditorComponent,
  Toolbar,
  Inject,
  Image,
  Link,
  HtmlEditor,
  Count,
  QuickToolbar,
  Table,
  EmojiPicker,
  RichTextEditor,
} from "@syncfusion/ej2-react-richtexteditor";

import { createElement } from "@syncfusion/ej2-base";
import { MentionComponent } from "@syncfusion/ej2-react-dropdowns";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";

import { isNullOrUndefined } from "@syncfusion/ej2-base";
// @ts-ignore
import * as CodeMirror from "codemirror";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";
import "./styles.scss";
import Editor from "./Editor";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
} from "../../Lib/Methods";
import {
  PerformRequest,
  UploadFiles,
  usePerformRequest,
} from "../../Lib/usePerformRequest";
import { NonPaginatedResponse } from "../../Lib/Responses";
import { BlogPost } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import Loader from "../../Components/Loader";

export default function UpdatePost() {
  const { postID } = useParams();
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname
  );
  let editor: RichTextEditor | undefined = undefined;
  const [isPreviewShowing, { toggle: TogglePreviewShowing }] =
    useDisclosure(false);
  useEffect(() => {
    document.title = "Create New Post";
  }, []);

  const post = useForm({
    initialValues: {
      id: "",
      title: "",
      tag: "",
      content: "",
      thumbnail: undefined,
    },
    validate: {
      title: (value) =>
        value.length === 0 ? "Your post must have a title!" : null,

      tag: (value) => (value.length === 0 ? "Enter a tag!" : null),
    },
  });
  const {
    data: postResponse,
    isLoading: isLoadingPost,
    reloadData: reloadPost,
  } = usePerformRequest<NonPaginatedResponse<BlogPost>>({
    url: `${Endpoints.GetSinglePost}/${postID}`,
    method: "GET",
  });

  useEffect(() => {
    if (postResponse && postResponse.data) {
      post.setValues({
        id: postResponse?.data?.id,
        title: postResponse?.data?.title,
        content: postResponse?.data?.content,
        tag: postResponse?.data?.tag,
      });
    }
  }, [postResponse]);
  const ShowPreview = () => {
    console.log(editor?.getHtml());
    post.setFieldValue("content", editor?.getHtml());
    TogglePreviewShowing();
  };

  const [shouldUploadImage, setShouldUploadImage] = useState<boolean>(false);
  const [isPostSaving, setPostSaving] = useState<boolean>(false);
  const SavePost = async () => {
    editor?.getHtml();
    editor?.getHtml();
    editor?.getHtml();
    editor?.getHtml();
    const { hasErrors } = post.validate();
    if (!hasErrors) {
      if (editor && editor?.getHtml() && editor?.getHtml()?.length !== 0) {
        const { id, tag, content, title, thumbnail } = post.values;
        const UploadImage = async () => {
          setPostSaving(true);
          if (shouldUploadImage) {
            const rUpload = await UploadFiles([thumbnail]);
            if (rUpload.status !== 201) {
              setPostSaving(false);
              DefaultErrorNotification("An unavoidable error occurred!");
            }
            const thumbnailURL = rUpload.data[0].path;
            return thumbnailURL;
          } else {
            return postResponse?.data?.thumbnail as string;
          }
        };

        const thumbnailImage = await UploadImage();
        const CreateNewPost = async () => {
          setPostSaving(true);
          console.log(editor?.getHtml());
          const rCreate = await PerformRequest<NonPaginatedResponse<BlogPost>>({
            method: "POST",
            route: Endpoints.CreatePost,
            data: {
              title,
              content: content,
              tag,
              thumbnail: thumbnailImage,
            },
            callback: () => {
              setPostSaving(false);
            },
          });

          if (rCreate.status === 201) {
            setPostSaving(false);
            post.setFieldValue("id", rCreate?.data?.data?.id);
            setShouldUploadImage(false);
            DefaultSuccessNotification("Post saved!");
          }
        };
        const UpdateCreatedPost = async () => {
          const thumbnailImage = await UploadImage();

          setPostSaving(true);
          const rUpdate = await PerformRequest({
            method: "PUT",
            route: Endpoints.UpdatePost,
            data: {
              id,
              title,
              content,
              thumbnail: thumbnailImage,
              tag,
            },
            callback: () => {
              setPostSaving(false);
            },
          });
          setPostSaving(false);
          if (rUpdate.status === 200) {
            DefaultSuccessNotification("Post saved!");
          } else {
            DefaultErrorNotification(
              "An error occurred. Your post was not saved!"
            );
          }
        };

        //POST HAS ALREADY BEEN CREATED SO UPDATE IT
        UpdateCreatedPost();
      } else {
        DefaultErrorNotification("Please add post content!");
      }
    }
  };
  const UpdatePostStatus = async (status: boolean) => {
    setPostSaving(true);
    const { id } = post.values;
    const r = await PerformRequest({
      method: "PUT",
      route: Endpoints.ChangePostStatus,
      data: {
        id,
        status,
      },
      callback: () => {
        setPostSaving(false);
      },
    });
    setPostSaving(false);
    if (r && r.status === 200) {
      DefaultSuccessNotification("Post status updated!");
      reloadPost();
    } else {
      DefaultErrorNotification("Could not be completed!");
    }
  };
  useEffect(() => {
    if (!isPreviewShowing) {
      editor?.updateValue(post.values.content);
    }
  }, [isPreviewShowing]);

  const [isPostPublished, setPostPublished] = useState<boolean>(false);

  let uploadObj;
  let mention;
  let codeMirror;
  const hostUrl = "https://services.syncfusion.com/react/production/";
  // Rich Text Editor items list
  const items = [
    "Undo",
    "Redo",
    "|",

    "|",
    "Bold",
    "Italic",
    "Underline",
    "StrikeThrough",
    "SuperScript",
    "SubScript",
    "|",
    "FontName",
    "FontSize",
    "FontColor",
    "BackgroundColor",
    "|",
    "LowerCase",
    "UpperCase",
    "|",
    "Formats",
    "Alignments",
    "Blockquote",
    "|",
    "NumberFormatList",
    "BulletFormatList",
    "|",
    "Outdent",
    "Indent",
    "|",
    "CreateLink",
    "Image",

    "CreateTable",
    "|",

    "|",
    "EmojiPicker",
    "Print",
    "|",
    "SourceCode",
  ];
  const rteValue = ``;

  const quickToolbarSettings = {
    table: [
      "TableHeader",
      "TableRows",
      "TableColumns",
      "TableCell",
      "-",
      "BackgroundColor",
      "TableRemove",
      "TableCellVerticalAlign",
      "Styles",
    ],
    showOnRightClick: true,
  };
  const insertImageSettings = {
    saveUrl: hostUrl + "api/RichTextEditor/SaveFile",
    removeUrl: hostUrl + "api/RichTextEditor/DeleteFile",
    path: hostUrl + "RichTextEditor/",
  };
  //Rich Text Editor ToolbarSettings
  const toolbarSettings = {
    items: items,
  };
  const uploadAsyncSettings = {
    saveUrl: hostUrl + "api/RichTextEditor/ImportFromWord",
  };
  function importContentFromWord() {
    uploadObj?.element?.click();
  }

  function onUploadSuccess(args: any) {
    editor?.executeCommand("insertHTML", args.e.currentTarget.response, {
      undo: true,
    });
  }
  function mirrorConversion(e) {
    const id = editor?.getID() + "mirror-view";
    const rteContainer = editor?.element.querySelector(".e-rte-container");
    let mirrorView = editor?.element.querySelector("#" + id);
    if (e.targetItem === "Preview") {
      editor.value = codeMirror?.getValue();
      editor?.dataBind();
      rteContainer?.classList?.remove("e-rte-code-mirror-enabled");
      editor?.focusIn();
    } else {
      rteContainer?.classList.add("e-rte-code-mirror-enabled");
      rteContainer?.classList.remove("e-source-code-enabled");
      if (!mirrorView) {
        mirrorView = createElement("div", {
          className: "rte-code-mirror",
          id: id,
          styles: "display: none;",
        });
        rteContainer?.appendChild(mirrorView);
        renderCodeMirror(
          mirrorView,
          editor?.value === null ? "" : editor?.value
        );
      } else {
        codeMirror?.setValue(editor?.value);
      }
      codeMirror?.focus();
    }
  }
  function renderCodeMirror(mirrorView: any, content: any) {
    codeMirror = CodeMirror(mirrorView, {
      value: content,
      lineNumbers: true,
      mode: "text/html",
      lineWrapping: true,
    });
  }
  function actionCompleteHandler(e) {
    if (
      e.targetItem &&
      (e.targetItem === "SourceCode" || e.targetItem === "Preview")
    ) {
      mirrorConversion(e);
    }
    if (e.requestType === "SourceCode") {
      editor
        ?.getToolbar()
        ?.querySelector("#custom_tbarbtn_1")
        ?.parentElement.classList.add("e-overlay");
      editor
        ?.getToolbar()
        ?.querySelector("#custom_tbarbtn_2")
        ?.parentElement.classList.add("e-overlay");
      editor
        ?.getToolbar()
        ?.querySelector("#custom_tbarbtn_3")
        ?.parentElement.classList.add("e-overlay");
    } else if (e.requestType === "Preview") {
      editor
        ?.getToolbar()
        ?.querySelector("#custom_tbarbtn_1")
        ?.parentElement.classList.remove("e-overlay");
      editor
        ?.getToolbar()
        ?.querySelector("#custom_tbarbtn_2")
        ?.parentElement.classList.remove("e-overlay");
      editor
        ?.getToolbar()
        ?.querySelector("#custom_tbarbtn_3")
        ?.parentElement.classList.remove("e-overlay");
    }
  }
  function quickToolbarOpenHandler(args) {
    if (
      !isNullOrUndefined(args.targetElement) &&
      args.targetElement.nodeName === "IMG"
    ) {
      editor
        ?.getToolbar()
        ?.querySelector("#custom_tbarbtn_1")
        ?.parentElement.classList.add("e-overlay");
      editor
        ?.getToolbar()
        ?.querySelector("#custom_tbarbtn_2")
        ?.parentElement.classList.add("e-overlay");
      editor
        ?.getToolbar()
        ?.querySelector("#custom_tbarbtn_3")
        ?.parentElement.classList.add("e-overlay");
    }
  }
  function quickToolbarClosehandler(args: any) {
    if (
      !isNullOrUndefined(args?.element) &&
      args?.element.classList.contains("e-rte-image-popup")
    ) {
      editor
        ?.getToolbar()
        ?.querySelector("#custom_tbarbtn_1")
        ?.parentElement.classList.remove("e-overlay");
      editor
        .getToolbar()
        .querySelector("#custom_tbarbtn_2")
        ?.parentElement.classList.remove("e-overlay");
      editor
        .getToolbar()
        .querySelector("#custom_tbarbtn_3")
        ?.parentElement.classList.remove("e-overlay");
    }
  }
  function actionBeginHandler(e) {
    if (
      e.requestType === "EnterAction" &&
      mention?.element.classList.contains("e-popup-open")
    ) {
      e.cancel = true;
    }
  }

  return (
    <>
      <motion.div
        initial={false}
        animate={{
          opacity: blocker.state === "blocked" ? 1 : 0,
        }}
        className="flex-row align-center justify-center width-100"
      >
        <Notification
          w={500}
          mt={40}
          style={{
            zIndex: blocker.state === "blocked" ? 99 : -1,
          }}
          pos="fixed"
          withBorder
          color="yellow"
          title="Confirm Exit"
          onClose={() => {
            blocker.reset();
          }}
        >
          <Text fz="sm">
            Are you sure you want to quit? Unsaved changes will be lost!
          </Text>
          <Button
            mt={10}
            w={120}
            color="var(--blue-primary)"
            onClick={() => {
              blocker.proceed();
            }}
          >
            Exit
          </Button>
        </Notification>
      </motion.div>
      {isLoadingPost ? (
        <Loader />
      ) : (
        <div className="create-post-container">
          {isPreviewShowing ? (
            <Container maxWidth="xl">
              <div className="e-richtexteditor">
                <div className="e-rte-content ">
                  <div className="e-content">
                    <div className="post-preview-container">
                      <Button
                        variant="subltle"
                        className="bg-blue-primary"
                        onClick={TogglePreviewShowing}
                      >
                        Exit
                      </Button>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: post.values.content,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          ) : (
            <Container maxWidth="xl">
              <div className="top width-100 flex-row align-end justify-between">
                <div className="left flex-col">
                  <Dropzone
                    w="fit-content"
                    mb={20}
                    accept={IMAGE_MIME_TYPE}
                    onReject={(rejection) => {
                      const messages = ["file-too-large", "file-invalid-type"];
                      const errs = rejection.map((r) => {
                        return r.errors.map((err) => {
                          return err.code;
                        });
                      });
                      const errors = [].concat(...errs);
                      if (errors.length > 0) {
                        errors.includes("file-too-large")
                          ? DefaultErrorNotification(
                              "Image must be less than 5MB"
                            )
                          : DefaultErrorNotification(
                              "You must upload an image!"
                            );
                      }
                    }}
                    maxSize={5 * 1024 ** 2}
                    maxFiles={1}
                    onDrop={(files) => {
                      if (files.length > 0) {
                        setShouldUploadImage(true);
                        post.setFieldValue("thumbnail", files[0]);
                      }
                    }}
                  >
                    {post.values.thumbnail ? (
                      <img
                        src={URL.createObjectURL(post.values.thumbnail)}
                        alt=""
                        className="thumbnail-image"
                      />
                    ) : (
                      <div className="thumbnail-blank flex-col align-center justify-center">
                        <Dropzone.Reject>
                          <IconX size={50} />
                        </Dropzone.Reject>
                        <Dropzone.Accept>
                          <IconUpload size={50} />
                        </Dropzone.Accept>
                        <Dropzone.Idle>
                          <img
                            src={postResponse?.data?.thumbnail}
                            alt=""
                            className="thumbnail-image"
                          />
                        </Dropzone.Idle>
                      </div>
                    )}
                  </Dropzone>
                  <div className="flex-row align-center">
                    <TextInput
                      {...post.getInputProps("title")}
                      variant="filled"
                      classNames={{
                        input: "title-input",
                      }}
                      placeholder="Post title..."
                      spellCheck={false}
                    />
                    <Tooltip withArrow label="E.g: Inventory Management">
                      <TextInput
                        {...post.getInputProps("tag")}
                        w={160}
                        ml={20}
                        tt="uppercase"
                        variant="filled"
                        placeholder="Post Tag..."
                        spellCheck={false}
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className="right flex-col align-end">
                  <div className="flex-row width-100 align-center justify-end">
                    <Tooltip label="Publish Post">
                      <Text mr={10} fz="sm">
                        Status
                      </Text>
                    </Tooltip>
                    <Switch
                      disabled={isPostSaving}
                      checked={postResponse?.data?.published}
                      onChange={(event) => {
                        UpdatePostStatus(event.currentTarget.checked);
                      }}
                      color="teal"
                      size="md"
                      thumbIcon={
                        postResponse?.data?.published ? (
                          <IconCheck
                            style={{ width: rem(12), height: rem(12) }}
                            color={"var(--green)"}
                            stroke={3}
                          />
                        ) : (
                          <IconX
                            style={{ width: rem(12), height: rem(12) }}
                            color={"var(--mantine-color-red-6)"}
                            stroke={3}
                          />
                        )
                      }
                    />
                    <Button
                      variant="outline"
                      className="preview-btn"
                      leftSection={<IconEye size={18} />}
                      w={120}
                      ml={10}
                      onClick={ShowPreview}
                    >
                      Preview
                    </Button>
                  </div>
                  <br />
                  <div className="flex-row align-center justify-between">
                    <Button
                      w={120}
                      className="bg-blue-primary"
                      onClick={SavePost}
                      loading={isPostSaving}
                    >
                      Save
                    </Button>
                    {/* <Button w={120} className="cancel-btn" color="#fa5252">
                      Cancel
                    </Button> */}
                  </div>
                </div>
              </div>
              <div className="control-pane">
                <div className="control-section" id="rteTools">
                  <div className="rte-control-section">
                    <RichTextEditorComponent
                      id="toolsRTE"
                      ref={(richtexteditor) => {
                        editor = richtexteditor;
                      }}
                      value={postResponse?.data?.content}
                      onChange={(e) => {
                        editor?.getHtml();
                      }}
                      onBlur={() => {
                        console.log(editor?.getHtml());
                        post.setFieldValue("content", editor?.getHtml());
                      }}
                      showCharCount={true}
                      actionBegin={actionBeginHandler.bind(this)}
                      actionComplete={actionCompleteHandler.bind(this)}
                      beforeQuickToolbarOpen={quickToolbarOpenHandler.bind(
                        this
                      )}
                      quickToolbarClose={quickToolbarClosehandler.bind(this)}
                      toolbarSettings={toolbarSettings}
                      quickToolbarSettings={quickToolbarSettings}
                      enableTabKey={true}
                      insertImageSettings={insertImageSettings}
                      enableXhtml={true}
                      placeholder="Create new post..."
                    >
                      <Inject
                        services={[
                          Toolbar,
                          Image,
                          Link,
                          HtmlEditor,
                          Count,
                          QuickToolbar,
                          Table,

                          EmojiPicker,
                        ]}
                      />
                    </RichTextEditorComponent>
                  </div>
                </div>
              </div>
            </Container>
          )}
        </div>
      )}
    </>
  );
}
