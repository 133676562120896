import { useContext, useEffect, useState } from "react";

import { Container } from "@mui/material";

import {
  Text,
  Button,
  Modal,
  ActionIcon,
  Table,
  TextInput,
  Popover,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";

import { team } from "../../Lib/Data";
import {
  IconEdit,
  IconPlus,
  IconReload,
  IconTrash,
  IconUserPlus,
} from "@tabler/icons-react";
import { AuthContext } from "../Dashboard";
import { TeamMember } from "../../Lib/Types";

import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
} from "../../Lib/Methods";

import "./styles.scss";
import Loader from "../../Components/Loader";
import { PerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";

export default function Team() {
  const authContext = useContext(AuthContext);

  const [currentMember, setCurrentMember] = useState<TeamMember>();

  const [isModalOpened, { open: OpenModal, close: CloseModal }] =
    useDisclosure(false);
  const [mode, setMode] = useState<"edit" | "create">("create");

  const memberForm = useForm<TeamMember>({
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
    },
    validate: {
      firstName: (value) =>
        value.length === 0 ? "Please enter First Name" : null,
      lastName: (value) =>
        value.length === 0 ? "Please enter Last Name" : null,
      email: (value) => (!validateEmail(value) ? "Enter a valid email!" : null),
    },
  });

  useEffect(() => {
    if (currentMember) {
      memberForm.setValues({
        id: currentMember.id,
        firstName: currentMember.firstName,
        lastName: currentMember.lastName,
        email: currentMember.email,
      });
    }
  }, [currentMember]);

  const [isLoading, setLoading] = useState<boolean>(false);

  const AddProfile = async () => {
    const { hasErrors } = memberForm.validate();
    if (!hasErrors) {
      const { firstName, lastName, email } = memberForm.values;
      setLoading(true);
      const r = await PerformRequest({
        method: "POST",
        data: { firstName, lastName, email },
        route: Endpoints.AddTeamMember,
        callback: () => {
          setLoading(false);
        },
      });
      setLoading(false);
      if (r?.status === 201) {
        DefaultSuccessNotification(r?.message);
        memberForm.reset();
        CloseModal();
        authContext?.reloadTeam();
      } else {
        DefaultErrorNotification(r?.message);
      }
    }
  };
  const DeleteProfile = async (memberID: string) => {
    setLoading(true);
    const r = await PerformRequest({
      method: "DELETE",
      route: `${Endpoints.DeleteTeamMember}/${memberID}`,
      callback: () => {
        setLoading(false);
      },
    });
    setLoading(false);
    if (r?.status === 200) {
      DefaultSuccessNotification(r?.message);
      memberForm.reset();
      CloseModal();
      authContext?.reloadTeam();
    } else {
      DefaultErrorNotification(r?.message);
    }
  };
  const UpdateProfile = async () => {
    const { hasErrors } = memberForm.validate();
    if (!hasErrors) {
      setLoading(true);
      const { firstName, lastName, email, id } = memberForm.values;
      const r = await PerformRequest({
        method: "PUT",
        data: { firstName, lastName, email, id },
        route: Endpoints.UpdateMemberProfile,
        callback: () => {
          setLoading(false);
        },
      });
      setLoading(false);
      if (r?.status === 201) {
        DefaultSuccessNotification(r?.message);
        memberForm.reset();
        CloseModal();
        authContext?.reloadTeam();
      } else {
        DefaultErrorNotification(r?.message);
      }
    }
  };
  return (
    <div className="team-container">
      <Container maxWidth="lg">
        <div className="flex-row page-header-row align-center justify-between width-100">
          <Text className="page-header">Admin Accounts</Text>
          <div className="flex-row align-center">
            <ActionIcon
              variant="outline"
              size="lg"
              loading={authContext?.isLoadingTeam}
              onClick={() => {
                authContext?.reloadTeam();
              }}
            >
              <IconReload stroke={1.8} size={17} />
            </ActionIcon>
            <ActionIcon
              size="lg"
              ml={20}
              onClick={() => {
                setMode("create");
                memberForm.reset();
                OpenModal();
              }}
            >
              <IconUserPlus stroke={1.8} size={17} />
            </ActionIcon>
          </div>
        </div>

        {authContext ? (
          <>
            {authContext.isLoadingTeam ? (
              <Loader />
            ) : (
              <Table verticalSpacing="md" withTableBorder>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>S/N</Table.Th>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Email</Table.Th>
                    <Table.Th>Actions</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {authContext?.team?.map((member, index) => {
                    return (
                      <Table.Tr key={member.id}>
                        <Table.Td>{index + 1}</Table.Td>
                        <Table.Td>
                          {member.firstName} {member.lastName}
                        </Table.Td>
                        <Table.Td>{member.email}</Table.Td>
                        <Table.Td>
                          <ActionIcon
                            size="xl"
                            variant="subtle"
                            color="var(--blue-primary)"
                            onClick={() => {
                              setCurrentMember(member);
                              setMode("edit");
                              OpenModal();
                            }}
                          >
                            <IconEdit />
                          </ActionIcon>
                          <Popover
                            width={300}
                            position="bottom"
                            withArrow
                            shadow="md"
                          >
                            <Popover.Target>
                              <ActionIcon
                                size="xl"
                                variant="subtle"
                                color="red"
                                ml={10}
                              >
                                <IconTrash />
                              </ActionIcon>
                            </Popover.Target>
                            <Popover.Dropdown>
                              <Text size="sm">
                                Are you sure you want to delete this member?
                              </Text>
                              <Text size="sm" c="red" my={7}>
                                This action CANNOT be undone o!
                              </Text>
                              <Button
                                color="red"
                                onClick={() => {
                                  DeleteProfile(member.id);
                                }}
                              >
                                Delete
                              </Button>
                            </Popover.Dropdown>
                          </Popover>
                        </Table.Td>
                      </Table.Tr>
                    );
                  })}
                </Table.Tbody>
              </Table>
            )}

            <Modal
              opened={isModalOpened}
              onClose={CloseModal}
              centered
              size="lg"
              title={`${mode === "create" ? "Add" : "Update"} Member Account`}
            >
              <div className="modal">
                <TextInput
                  {...memberForm.getInputProps("email")}
                  placeholder="Enter Member email"
                  label="Email Address"
                  mb={20}
                />
                <div className="row flex-row align-center justify-between width-100">
                  <TextInput
                    w="48%"
                    {...memberForm.getInputProps("firstName")}
                    placeholder="Enter First Name"
                    label="First Name"
                  />
                  <TextInput
                    w="48%"
                    {...memberForm.getInputProps("lastName")}
                    placeholder="Enter Last Name"
                    label="Last Name"
                  />
                </div>
                <Button
                  fullWidth
                  mt={20}
                  onClick={mode === "create" ? AddProfile : UpdateProfile}
                  loading={isLoading}
                >
                  {mode === "create" ? "Add" : "Update"} Profile
                </Button>
              </div>
            </Modal>
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </div>
  );
}
