import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Group,
  Button,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import Logo from "../../../Assets/IMG/HalfLogo.svg";
import "./styles.scss";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
} from "../../../Lib/Methods";
import { PerformRequest } from "../../../Lib/usePerformRequest";
import { Endpoints } from "../../../Lib/Endpoints";
import { NonPaginatedResponse } from "../../../Lib/Responses";

export default function Login() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  const loginForm = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Enter a valid email address",
      password: (value) =>
        value.length === 0 ? "Please enter a password" : null,
    },
  });
  const LoginUser = async () => {
    const { hasErrors } = loginForm.validate();
    if (!hasErrors) {
      setLoading(true);
      const { email, password } = loginForm.values;
      const r = await PerformRequest<NonPaginatedResponse<string>>({
        method: "POST",
        route: Endpoints.Login,
        data: { email, password },
        callback: () => setLoading(false),
      });
      setLoading(false);
      if (r.status === 200) {
        DefaultSuccessNotification("Login successful!");
        localStorage.setItem("token", r.data.data);
        navigate("/dashboard");
      } else {
        DefaultErrorNotification(r?.message);
      }
    }
  };
  return (
    <div className="login-container">
      <div className="login">
        <div className="flex-row width-100 align-center justify-center">
          <img src={Logo} alt="" className="logo" />
        </div>
        <Title ta="center" className="title">
          Welcome back!
        </Title>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            {...loginForm.getInputProps("email")}
            label="Email"
            placeholder="you@email.com"
            required
          />
          <PasswordInput
            {...loginForm.getInputProps("password")}
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                LoginUser();
              }
            }}
          />
          <Group justify="space-between" mt="lg">
            <span></span>
            <Link to="/forgot-password">
              <Anchor component="button" size="sm">
                Forgot password?
              </Anchor>
            </Link>
          </Group>
          <Button
            fullWidth
            mt="xl"
            loading={isLoading}
            className="bg-blue-primary"
            onClick={LoginUser}
          >
            Sign in
          </Button>
        </Paper>
      </div>
    </div>
  );
}
