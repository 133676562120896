import { useState } from "react";
import { Container, Grid, GridProps } from "@mui/material";

import { ActionIcon, Text, TextInput } from "@mantine/core";

import "./styles.scss";
import { Statistic } from "../../Lib/Types";
import { IconCheck, IconEdit, IconReload, IconX } from "@tabler/icons-react";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { NonPaginatedResponse } from "../../Lib/Responses";
import { Endpoints } from "../../Lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
} from "../../Lib/Methods";
import Loader from "../../Components/Loader";
export default function Stats() {
  const {
    data: stats,
    reloadData: reloadStats,
    isLoading: isLoadingStats,
  } = usePerformRequest<NonPaginatedResponse<Statistic[]>>({
    url: Endpoints.GetStats,
    method: "GET",
  });
  const gridItemProps: GridProps = {
    xl: 3,
    lg: 3,
    md: 3,
    sm: 6,
    xs: 12,
  };
  const [currentStat, setCurrentStat] = useState<Statistic>();

  const [isLoading, setLoading] = useState<boolean>(false);
  const UpdateStat = async () => {
    setLoading(true);
    const r = await PerformRequest({
      method: "PUT",
      route: Endpoints.UpdateStat,
      data: {
        id: currentStat?.id,
        title: currentStat?.title,
        value: currentStat?.value,
      },
      callback: () => {
        setLoading(false);
      },
    });
    setLoading(false);
    if (r.status === 200) {
      DefaultSuccessNotification(r?.message);
      reloadStats();
      setCurrentStat(undefined);
    } else {
      DefaultErrorNotification(
        r?.message ?? "An error occurred while updating value!"
      );
    }
  };
  return (
    <div className="stats-container">
      <Container maxWidth="lg">
        <div className="flex-row page-header-row align-center justify-between width-100">
          <Text tt="capitalize" className="page-header">
            Stats
          </Text>

          <ActionIcon
            loading={isLoadingStats}
            size="lg"
            ml={20}
            onClick={reloadStats}
          >
            <IconReload stroke={1.8} size={17} />
          </ActionIcon>
        </div>
        {isLoadingStats ? (
          <Loader />
        ) : (
          <Grid container justifyContent="space-between">
            {stats?.data?.map((stat) => {
              return (
                <Grid
                  className={`stat ${
                    currentStat?.id === stat.id ? "stat-active" : ""
                  }`}
                  key={stat.id}
                  {...gridItemProps}
                >
                  {currentStat?.id === stat.id ? (
                    <>
                      <TextInput
                        placeholder="Enter stat title"
                        spellCheck={false}
                        label="Title"
                        variant="filled"
                        value={currentStat?.title}
                        onChange={(e) => {
                          setCurrentStat({
                            ...currentStat,
                            title: e.target.value,
                          });
                        }}
                        disabled
                      />
                      <TextInput
                        my={15}
                        placeholder="Enter stat value"
                        label="Value"
                        value={currentStat.value}
                        onChange={(e) => {
                          setCurrentStat({
                            ...currentStat,
                            value: e.target.value,
                          });
                        }}
                      />
                      <div className="flex-row align-center width-100 justify-between">
                        <ActionIcon
                          color="red"
                          size="lg"
                          w="48%"
                          onClick={() => {
                            setCurrentStat(undefined);
                          }}
                        >
                          <IconX size={19} />
                        </ActionIcon>
                        <ActionIcon
                          color="green"
                          size="lg"
                          w="48%"
                          onClick={() => {
                            UpdateStat();
                          }}
                          loading={isLoading}
                        >
                          <IconCheck size={19} />
                        </ActionIcon>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex-row align-center justify-between width-100">
                        <Text className=" label">{stat?.title}</Text>
                        <ActionIcon
                          size="lg"
                          variant="subtle"
                          color="var(--blue-primary)"
                          onClick={() => {
                            setCurrentStat(stat);
                          }}
                        >
                          <IconEdit size={19} />
                        </ActionIcon>
                      </div>
                      <Text className="value">{stat?.value}</Text>
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </div>
  );
}
