import { Link } from "react-router-dom";

import { Container, Grid, Grid2Props, GridProps } from "@mui/material";

import {
  Text,
  Button,
  NavLink,
  Card,
  useMantineTheme,
  rem,
  ActionIcon,
  Pagination,
  Popover,
  Tooltip,
  Chip,
  Badge,
} from "@mantine/core";

import {
  IconEdit,
  IconExternalLink,
  IconPencilPlus,
  IconReload,
  IconTrash,
  IconUserPlus,
} from "@tabler/icons-react";

import "./styles.scss";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import { NonPaginatedResponse, PaginatedResponse } from "../../Lib/Responses";
import { BlogPost } from "../../Lib/Types";
import Loader from "../../Components/Loader";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getEllipsisWithString,
} from "../../Lib/Methods";

export default function Blog() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [pagination, setPagination] = useState<{ page: number; limit: number }>(
    {
      page: 1,
      limit: 20,
    }
  );
  const {
    data: posts,
    response: postsResponse,
    isLoading: isLoadingPosts,
    reloadData: reloadPosts,
  } = usePerformRequest<PaginatedResponse<BlogPost[]>>({
    method: "POST",
    url: Endpoints.GetBlogPosts,
    body: pagination,
  });

  const [isDeletingPost, setDeletingPost] = useState<boolean>(false);

  const gridItemProps: GridProps = {
    xl: 3,
    lg: 3,
    md: 6,
    sm: 12,
    xs: 12,
  };
  const [isPostFeaturing, setPostFeaturing] = useState(false);
  return (
    <div className="blogs-container">
      <Container maxWidth="lg">
        <div className="flex-row page-header-row align-center justify-between width-100">
          <Text className="page-header">Blog Posts</Text>
          <div className="flex-row align-center">
            <ActionIcon
              variant="outline"
              size="lg"
              loading={isLoadingPosts}
              onClick={() => {
                reloadPosts();
              }}
            >
              <IconReload stroke={1.8} size={17} />
            </ActionIcon>
            <Link to="/dashboard/create-post">
              <ActionIcon size="lg" ml={20}>
                <IconPencilPlus stroke={1.8} size={17} />
              </ActionIcon>
            </Link>
          </div>
        </div>
        {isLoadingPosts ? (
          <Loader />
        ) : (
          <>
            {posts && posts.data && (
              <Grid
                container
                className="posts"
                alignItems="center"
                justifyContent="center"
              >
                {posts?.data?.map((post, index) => {
                  const SetFeaturedPost = async () => {
                    setPostFeaturing(true);
                    const r = await PerformRequest({
                      method: "PUT",
                      route: Endpoints.SetFeaturedPost,
                      data: { id: post.id },
                      callback: () => {
                        setPostFeaturing(false);
                      },
                    });
                    setPostFeaturing(false);
                    if (r && r.status === 200) {
                      DefaultSuccessNotification("Completed!");
                      reloadPosts();
                    } else {
                      DefaultErrorNotification("An error occurred!");
                    }
                  };
                  const DeletePost = async () => {
                    setDeletingPost(true);
                    const r = await PerformRequest({
                      method: "DELETE",
                      route: Endpoints.DeletePost,
                      data: {
                        id: post.id,
                      },
                      callback: () => {
                        setDeletingPost(false);
                      },
                    });
                    setDeletingPost(false);
                    if (r && r.status === 200) {
                      DefaultSuccessNotification("Post has been deleted!");
                      reloadPosts();
                    }
                  };
                  return (
                    <Grid
                      className="post flex-col justify-between"
                      style={{
                        backgroundImage: `url(${post.thumbnail})`,
                      }}
                      key={post.id}
                      {...gridItemProps}
                    >
                      <div className="top flex-col">
                        <Text className="tag" tt="uppercase">
                          {post.tag}
                        </Text>
                        <Text className="title">
                          {getEllipsisWithString(post.title, 30)}
                        </Text>
                      </div>
                      <div className="flex-col">
                        {post.featured ? (
                          <Badge mb={10} color="green">
                            Featured
                          </Badge>
                        ) : (
                          <Tooltip label="Chip tooltip" refProp="rootRef">
                            <Button
                              mb={10}
                              size="xs"
                              w={140}
                              onClick={SetFeaturedPost}
                              disabled={isPostFeaturing}
                            >
                              Set as Featured
                            </Button>
                          </Tooltip>
                        )}
                        <div className="flex-row align-center">
                          <Link to={`/dashboard/post/${post.id}`}>
                            <ActionIcon
                              className="btn"
                              color="var(--white)"
                              c="var(--darkest-green)"
                              size="xl"
                            >
                              <IconEdit size={19} />
                            </ActionIcon>
                          </Link>

                          <Popover
                            width={300}
                            position="bottom"
                            withArrow
                            shadow="md"
                          >
                            <Popover.Target>
                              <ActionIcon
                                ml={10}
                                className="btn"
                                color="red"
                                size="xl"
                              >
                                <IconTrash size={19} />
                              </ActionIcon>
                            </Popover.Target>
                            <Popover.Dropdown>
                              <Text size="sm">
                                Are you sure you want to delete this post?
                              </Text>
                              <Text size="sm" c="red" my={7}>
                                This action CANNOT be undone o!
                              </Text>
                              <Button
                                color="red"
                                disabled={isDeletingPost}
                                onClick={DeletePost}
                              >
                                Remove
                              </Button>
                            </Popover.Dropdown>
                          </Popover>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </>
        )}
        <div className="flex-row width-100 justify-center align-center">
          <Pagination
            mt={10}
            disabled={isLoadingPosts}
            value={pagination.page}
            onChange={(e) => {
              setPagination({ ...pagination, page: e });
            }}
            total={Math.ceil((posts?.totalRecords as number) / 20)}
            //   total={Math.ceil((orders?.totalRecords as number) / 20)}
          />
        </div>
      </Container>
    </div>
  );
}
