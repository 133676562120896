import { useEffect, useState } from "react";
import { Container, Grid, GridProps } from "@mui/material";

import { ActionIcon, Button, Popover, Text, TextInput } from "@mantine/core";

import "./styles.scss";
import { QuestionProps } from "../../Lib/Types";
import {
  IconCheck,
  IconEdit,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  generateRandomString,
  getEllipsisWithString,
} from "../../Lib/Methods";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { NonPaginatedResponse } from "../../Lib/Responses";
import { Endpoints } from "../../Lib/Endpoints";
import Loader from "../../Components/Loader";
export default function FrequentlyAskedQuestions() {
  const {
    data: questionsData,
    reloadData: reloadQuestions,
    isLoading: isLoadingQuestions,
  } = usePerformRequest<NonPaginatedResponse<QuestionProps[]>>({
    method: "GET",
    url: Endpoints.GetQuestions,
  });
  const gridItemProps: GridProps = {
    xl: 4,
    lg: 4,
    md: 6,
    sm: 12,
    xs: 12,
  };
  const [currentQuestion, setCurrentQuestion] = useState<QuestionProps>();

  const [questions, setQuestions] = useState<QuestionProps[]>([]);

  useEffect(() => {
    if (questionsData && questionsData.data) {
      setQuestions(questionsData.data);
    }
  }, [questionsData]);

  const [isLoading, setLoading] = useState<boolean>(false);

  const UpdateQuestion = async () => {
    const { id, title, value } = currentQuestion as QuestionProps;
    if (!id) {
      //CREATE NEW QUESTION
      setLoading(true);
      const r = await PerformRequest({
        method: "POST",
        route: Endpoints.AddQuestion,
        data: {
          title,
          value,
        },
        callback: () => {
          setLoading(false);
        },
      });
      setLoading(false);
      if (r?.status === 201) {
        DefaultSuccessNotification("Question has been added!");
        setCurrentQuestion(undefined);
        setQuestions([]);
        reloadQuestions();
      } else {
        DefaultErrorNotification(
          r?.message ?? "An error occurred while creating question"
        );
      }
    } else {
      // UPDATE EXISTING QUESTION
      setLoading(true);

      const r = await PerformRequest({
        method: "PUT",
        route: Endpoints.UpdateQuestion,
        data: {
          id,
          title,
          value,
        },
        callback: () => {
          setLoading(false);
        },
      });
      setLoading(false);
      if (r?.status === 200) {
        DefaultSuccessNotification(r?.message);
        setCurrentQuestion(undefined);
        setQuestions([]);
        reloadQuestions();
      } else {
        DefaultErrorNotification(r?.message ?? "Could not update question");
      }
    }
  };

  const DeleteQuestion = async () => {
    if (currentQuestion && currentQuestion.id) {
      setLoading(true);
      const r = await PerformRequest({
        method: "DELETE",
        data: {
          id: currentQuestion?.id ?? "no-such-question",
        },
        callback: () => {
          setLoading(false);
        },
        route: Endpoints.DeleteQuestion,
      });
      setLoading(false);
      if (r?.status === 200) {
        DefaultSuccessNotification(r?.message);
        setQuestions([]);
        reloadQuestions();
      } else {
        DefaultErrorNotification(r?.message ?? "Could not delete question");
      }
    } else {
      setQuestions((prevQuestions) => [
        ...prevQuestions.filter((quest) => quest.id !== undefined),
      ]);
    }
  };
  return (
    <div className="faq-container">
      <Container maxWidth="lg">
        <div className="flex-row page-header-row align-center justify-between width-100">
          <Text className="page-header">FAQs</Text>

          {!isLoadingQuestions && (
            <ActionIcon
              size="lg"
              ml={20}
              onClick={() => {
                const isEmptyQuestion =
                  questions.filter((q) => q?.id === undefined).length > 0;
                if (!isEmptyQuestion) {
                  const newQuestion: QuestionProps = {
                    id: undefined as unknown as string,
                    title: "",
                    value: "",
                  };
                  setQuestions((prevQuestions) => [
                    ...prevQuestions,
                    newQuestion,
                  ]);
                  setCurrentQuestion(newQuestion);
                } else {
                  DefaultErrorNotification("You have an empty question");
                }
              }}
            >
              <IconPlus stroke={1.8} size={17} />
            </ActionIcon>
          )}
        </div>
        {isLoadingQuestions ? (
          <Loader />
        ) : (
          <Grid container>
            {questions?.map((question) => {
              return (
                <Grid
                  className={`question ${
                    currentQuestion?.id === question.id ? "question-active" : ""
                  }`}
                  key={question.id}
                  {...gridItemProps}
                >
                  {currentQuestion?.id === question.id ? (
                    <>
                      <TextInput
                        placeholder="Enter question title"
                        spellCheck={false}
                        label="Title"
                        value={currentQuestion?.title}
                        onChange={(e) => {
                          setCurrentQuestion({
                            ...currentQuestion,
                            title: e.target.value,
                          });
                        }}
                      />
                      <TextInput
                        my={15}
                        placeholder="Enter question value"
                        label="Value"
                        value={currentQuestion.value}
                        onChange={(e) => {
                          setCurrentQuestion({
                            ...currentQuestion,
                            value: e.target.value,
                          });
                        }}
                      />
                      <div className="flex-row align-center width-100 justify-between">
                        <ActionIcon
                          color="red"
                          size="lg"
                          w="32%"
                          onClick={() => {
                            setQuestions((prevQuestions) => [
                              ...prevQuestions.filter(
                                (quest) => quest.id !== undefined
                              ),
                            ]);
                            if (currentQuestion && currentQuestion.id) {
                              setCurrentQuestion(undefined);
                            }
                          }}
                        >
                          <IconX size={19} />
                        </ActionIcon>
                        <ActionIcon
                          color="green"
                          size="lg"
                          w="32%"
                          loading={isLoading}
                          onClick={() => {
                            UpdateQuestion();
                          }}
                        >
                          <IconCheck size={19} />
                        </ActionIcon>
                        <Popover
                          width={300}
                          position="bottom"
                          withArrow
                          shadow="md"
                        >
                          <Popover.Target>
                            <ActionIcon
                              disabled={isLoading}
                              color="red"
                              size="lg"
                              w="32%"
                            >
                              <IconTrash size={19} />
                            </ActionIcon>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Text size="sm">
                              Are you sure you want to delete this question?
                            </Text>
                            <Text size="sm" c="red" my={7}>
                              This action CANNOT be undone o!
                            </Text>
                            <Button
                              color="red"
                              loading={isLoading}
                              onClick={DeleteQuestion}
                            >
                              Delete
                            </Button>
                          </Popover.Dropdown>
                        </Popover>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex-row align-center justify-between width-100">
                        <Text className=" label">{question.title}</Text>
                        <ActionIcon
                          size="lg"
                          variant="subtle"
                          color="var(--blue-primary)"
                          onClick={() => {
                            setCurrentQuestion(question);
                          }}
                        >
                          <IconEdit size={19} />
                        </ActionIcon>
                      </div>
                      <Text className="value">
                        {getEllipsisWithString(question.value, 190)}
                      </Text>
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </div>
  );
}
