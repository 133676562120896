import { createContext, useEffect } from "react";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import CreatePost from "../CreatePost";
import Overview from "../Overview";
import Navbar from "../../Components/Navbar";

import "./styles.scss";
import Team from "../Team";
import Responses from "../Responses";
import Stats from "../Stats";
import FrequentlyAskedQuestions from "../FAQs";
import { usePerformRequest } from "../../Lib/usePerformRequest";
import { NewsletterRecipient, OrderProps, TeamMember } from "../../Lib/Types";
import { Endpoints } from "../../Lib/Endpoints";
import { AuthContextProps } from "../../Context/AuthContext";
import { NonPaginatedResponse } from "../../Lib/Responses";
import Blog from "../Blog";
import SkeletonLoader from "../../Components/Loader";
import UpdatePost from "../UpdatePost";

export const AuthContext = createContext<AuthContextProps>(undefined);
export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: profile,
    response: profileResponse,
    reloadData: reloadProfile,
    isLoading: isLoadingProfile,
  } = usePerformRequest<NonPaginatedResponse<TeamMember>>({
    method: "GET",
    url: Endpoints.GetProfile,
  });
  const {
    data: recipients,
    reloadData: reloadRecipients,
    isLoading: isLoadingRecipients,
  } = usePerformRequest<NonPaginatedResponse<NewsletterRecipient[]>>({
    method: "GET",
    url: Endpoints.GetNewsletterRecipients,
  });
  const {
    data: team,
    reloadData: reloadTeam,
    isLoading: isLoadingTeam,
  } = usePerformRequest<NonPaginatedResponse<TeamMember[]>>({
    method: "GET",
    url: Endpoints.GetTeam,
  });

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      logout();
    } else {
      if (profileResponse?.status === 401) {
        logout();
      }
    }
  }, [location]);

  return (
    <AuthContext.Provider
      value={{
        profile: profile?.data,
        isLoadingProfile,
        reloadProfile,
        team: team?.data,
        isLoadingTeam,
        reloadTeam,
        recipients: recipients?.data,
        isLoadingRecipients,
        reloadRecipients,
        logout,
      }}
    >
      <div className="dashboard-container">
        <Navbar />
        <Routes>
          <Route path="/" index element={<Overview />} />
          <Route path="/team" index element={<Team />} />
          <Route path="/blog" index element={<Blog />} />
          <Route path="/stats" index element={<Stats />} />
          <Route path="/faq" index element={<FrequentlyAskedQuestions />} />
          <Route path="/responses" index element={<Responses />} />
          <Route path="/create-post" element={<CreatePost />} />
          <Route path="/post/:postID" element={<UpdatePost />} />
        </Routes>
        {/* <RouterProvider router={router} /> */}
      </div>
    </AuthContext.Provider>
  );
}
