import React, { useContext, useEffect, useState } from "react";

import { Container } from "@mui/material";
import {
  CopyButton,
  Text,
  Tabs,
  rem,
  ActionIcon,
  Table,
  Popover,
  Button,
  Badge,
  Modal,
  PaginationProps,
  Pagination,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import {
  IconCopy,
  IconCopyCheckFilled,
  IconExternalLink,
  IconMail,
  IconReload,
  IconTrash,
  IconTruckDelivery,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { NewsletterRecipient, OrderProps } from "../../Lib/Types";

import "./styles.scss";
import { AuthContext } from "../Dashboard";
import Loader from "../../Components/Loader";
import { PerformRequest, usePerformRequest } from "../../Lib/usePerformRequest";
import { Endpoints } from "../../Lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
} from "../../Lib/Methods";
import { PaginatedResponse } from "../../Lib/Responses";
export default function Responses() {
  const iconStyle = { width: rem(18), height: rem(18) };
  const context = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState<"newsletter" | "orders">(
    "newsletter"
  );
  const [isReloadingOrders, setReloadingOrders] = useState<boolean>(false);
  return (
    <div className="responses-container">
      <Container maxWidth="lg">
        <div className="flex-row page-header-row align-center justify-between width-100">
          <Text tt="capitalize" className="page-header">
            {activeTab}
          </Text>
          <div className="flex-row align-center">
            <ActionIcon
              variant="outline"
              size="lg"
              onClick={() => {
                if (activeTab === "newsletter") {
                  context?.reloadRecipients();
                } else {
                  setReloadingOrders(true);
                }
              }}
            >
              <IconReload stroke={1.8} size={17} />
            </ActionIcon>
          </div>
        </div>
        <Tabs
          radius="xs"
          defaultValue="gallery"
          value={activeTab}
          onChange={setActiveTab as any}
        >
          <Tabs.List grow>
            <Tabs.Tab
              value="newsletter"
              leftSection={
                <IconMail color="var(--blue-primary)" style={iconStyle} />
              }
            >
              <Text fw={500} c="var(--blue-primary)">
                Newsletter
              </Text>
            </Tabs.Tab>
            <Tabs.Tab
              value="orders"
              leftSection={
                <IconTruckDelivery
                  color="var(--blue-primary)"
                  style={iconStyle}
                />
              }
            >
              <Text fw={500} c="var(--blue-primary)">
                Orders
              </Text>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="newsletter">
            <NewsletterTable />
          </Tabs.Panel>

          <Tabs.Panel value="orders">
            <OrdersTable
              isReloadingOrders={isReloadingOrders}
              setReloadingOrders={setReloadingOrders}
            />
          </Tabs.Panel>
        </Tabs>
      </Container>
    </div>
  );
}

interface TableProps {
  isReloadingOrders: boolean;
  setReloadingOrders: React.Dispatch<React.SetStateAction<boolean>>;
}
function NewsletterTable() {
  const context = useContext(AuthContext);

  const [isLoading, setLoading] = useState<boolean>(false);

  return (
    <>
      {context ? (
        <>
          {context.isLoadingRecipients ? (
            <Loader />
          ) : (
            <Table verticalSpacing="md" withTableBorder my={20}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Email</Table.Th>
                  <Table.Th>Sign Up Date</Table.Th>
                  <Table.Th>Actions</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {context?.recipients?.map((recipient) => {
                  const RemoveRecipient = async () => {
                    setLoading(true);
                    const r = await PerformRequest({
                      method: "DELETE",
                      route: `${Endpoints.RemoveFromNewsletter}`,
                      data: { email: recipient.email, id: recipient.id },
                      callback: () => {
                        setLoading(false);
                      },
                    });
                    setLoading(false);
                    if (r?.status === 200) {
                      DefaultSuccessNotification(r?.message);

                      context?.reloadRecipients();
                    } else {
                      DefaultErrorNotification(r?.message);
                    }
                  };
                  return (
                    <Table.Tr key={recipient.id}>
                      <Table.Td>
                        <Text tt="lowercase" className="value">
                          {recipient.email}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text className="value">
                          {dayjs(recipient.timestamp).format("DD MMM YYYY")}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <div className="flex-row align-center">
                          <CopyButton value={recipient.email}>
                            {({ copied, copy }) => (
                              <ActionIcon
                                variant="subtle"
                                color={copied ? "teal" : "var(--blue-primary)"}
                                onClick={copy}
                                size="lg"
                              >
                                {copied ? (
                                  <IconCopyCheckFilled size={19} />
                                ) : (
                                  <IconCopy size={19} />
                                )}
                              </ActionIcon>
                            )}
                          </CopyButton>
                          <Popover
                            width={300}
                            position="bottom"
                            withArrow
                            shadow="md"
                          >
                            <Popover.Target>
                              <ActionIcon
                                size="lg"
                                variant="subtle"
                                color="red"
                                ml={10}
                              >
                                <IconTrash size={19} />
                              </ActionIcon>
                            </Popover.Target>
                            <Popover.Dropdown>
                              <Text size="sm">
                                Are you sure you want to remove this recipient?
                              </Text>
                              <Text size="sm" c="red" my={7}>
                                This action CANNOT be undone o!
                              </Text>
                              <Button
                                loading={isLoading}
                                color="red"
                                onClick={RemoveRecipient}
                              >
                                Remove
                              </Button>
                            </Popover.Dropdown>
                          </Popover>
                        </div>
                      </Table.Td>
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </Table>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
function OrdersTable({ isReloadingOrders, setReloadingOrders }: TableProps) {
  const context = useContext(AuthContext);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [pagination, setPagination] = useState<{ page: number; limit: number }>(
    {
      page: 1,
      limit: 20,
    }
  );
  const {
    data: orders,
    reloadData: reloadOrders,
    isLoading: isLoadingOrders,
  } = usePerformRequest<PaginatedResponse<OrderProps[]>>({
    method: "POST",
    url: Endpoints.GetOrders,
    body: pagination,
  });

  useEffect(() => {
    reloadOrders();
  }, [pagination]);
  useEffect(() => {
    if (isReloadingOrders) {
      reloadOrders();
    }
  }, [isReloadingOrders]);
  useEffect(() => {
    if (!isLoadingOrders) {
      setReloadingOrders(false);
    }
  }, [isLoadingOrders]);
  const [isModalOpen, { open: OpenModal, close: CloseModal }] =
    useDisclosure(false);
  const [currentOrder, setCurrentOrder] = useState<OrderProps>();

  const ResolveOrReject = async () => {
    setLoading(true);
    const r = await PerformRequest({
      method: "PUT",
      route: Endpoints.UpdateOrderStatus,
      data: {
        orderID: currentOrder?.id,
        resolved: !currentOrder?.resolved,
      },
      callback: () => {
        setLoading(false);
      },
    });
    setLoading(false);
    if (r.status === 200) {
      DefaultSuccessNotification("Order updated successfully!");
      await reloadOrders();
      CloseModal();
      setCurrentOrder(undefined);
    }
  };
  return (
    <>
      {isLoadingOrders ? (
        <Loader />
      ) : (
        <>
          <Table verticalSpacing="md" withTableBorder my={20}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Email</Table.Th>
                <Table.Th>Service</Table.Th>
                <Table.Th>Order Date</Table.Th>
                <Table.Th>Status</Table.Th>
                <Table.Th>Actions</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {orders?.data?.map((order) => {
                return (
                  <Table.Tr key={order.id}>
                    <Table.Td>
                      <Text className="value">{order.name}</Text>
                    </Table.Td>
                    <Table.Td>
                      <Text className="value" tt="lowercase">
                        {order.email}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text tt="capitalize" className="value">
                        {order.service.replaceAll("-", " ")}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Text className="value">
                        {dayjs(order.timestamp).format("DD MMM YYYY")}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Badge color={order.resolved ? "green" : "orange"}>
                        {order.resolved ? "Resolved" : "Pending"}
                      </Badge>
                    </Table.Td>
                    <Table.Td>
                      <ActionIcon
                        size="lg"
                        variant="subtle"
                        color="var(--blue-primary)"
                        onClick={() => {
                          setCurrentOrder(order);
                          OpenModal();
                        }}
                      >
                        <IconExternalLink size={19} />
                      </ActionIcon>
                    </Table.Td>
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
          <div className="flex-row width-100 justify-center align-center">
            <Pagination
              mt={10}
              value={pagination.page}
              onChange={(e) => {
                setPagination({ ...pagination, page: e });
              }}
              total={Math.ceil((orders?.totalRecords as number) / 20)}
            />
          </div>
          <Modal
            title={`Order from ${currentOrder?.name}`}
            opened={isModalOpen}
            onClose={CloseModal}
            size="lg"
            centered
          >
            <>
              {currentOrder && (
                <div className="single-order">
                  <div className="flex-row align-center">
                    <Text fw={600} mr={7}>
                      Name:
                    </Text>
                    <Text>{currentOrder.name}</Text>
                  </div>
                  <br />
                  <div className="flex-row align-center">
                    <Text fw={600} mr={7}>
                      Status:
                    </Text>
                    <Text c={currentOrder.resolved ? "green" : "orange"}>
                      {currentOrder.resolved ? "Resolved" : "Pending"}
                    </Text>
                  </div>
                  <br />
                  <div className="flex-row align-center">
                    <Text fw={600} mr={7}>
                      Email:
                    </Text>
                    <CopyButton value={currentOrder.email}>
                      {({ copied, copy }) => (
                        <Text
                          style={{ cursor: "pointer" }}
                          color={copied ? "green" : "var(--blue-primary)"}
                          onClick={copy}
                          tt="lowercase"
                        >
                          {currentOrder.email}
                        </Text>
                      )}
                    </CopyButton>
                  </div>
                  <br />
                  <div className="flex-row align-center">
                    <Text fw={600} mr={7}>
                      Service:
                    </Text>
                    <Text tt="capitalize">
                      {currentOrder.service.replaceAll("-", " ")}
                    </Text>
                  </div>
                  <br />
                  <div className="flex-col">
                    <Text fw={600} mr={7}>
                      Comments:
                    </Text>
                    <Text tt="capitalize">{currentOrder.comments}</Text>
                  </div>
                  <br />
                  <Button
                    color={!currentOrder.resolved ? "green" : "orange"}
                    loading={isLoading}
                    onClick={ResolveOrReject}
                  >
                    Mark as {currentOrder.resolved ? "Pending" : "Resolved"}
                  </Button>
                </div>
              )}
            </>
          </Modal>
        </>
      )}
    </>
  );
}
