import { Skeleton } from "@mantine/core";
import { Container } from "@mui/material";

export default function Loader() {
  return (
    <Container maxWidth="lg">
      <br />
      <Skeleton h={30} w="100%" />
      <Skeleton h={30} w="100%" />
      <Skeleton h={30} w="100%" />
      <Skeleton h={30} w="100%" />
      <Skeleton h={30} w="100%" />
      <Skeleton h={30} w="100%" />
      <Skeleton h={30} w="100%" />
      <br />
    </Container>
  );
}
