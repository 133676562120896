import { Link, useNavigate } from "react-router-dom";

import { Container, Grid, GridProps } from "@mui/material";
import { Text, Button } from "@mantine/core";

import { links } from "../../Components/Navbar";
import "./styles.scss";

export default function Overview() {
  const navigate = useNavigate();
  const gridItemProps: GridProps = {
    item: true,
    className: "item",
    xl: 2.4,
    lg: 2.4,
    md: 3,
    sm: 4,
    xs: 6,
  };
  return (
    <div className="overview-container flex-row justify-center">
      <Container maxWidth="lg">
        <Text className="page-header" mb={20}>
          Overview
        </Text>
        <Grid container className="items">
          {links.map((link) => {
            return (
              <Grid
                key={link.label}
                {...gridItemProps}
                // @ts-ignore
                onClick={() => {
                  navigate(`/dashboard/${link.link}`);
                }}
              >
                <link.icon size={40} stroke={1.2} color="var(--blue-primary)" />
                <Text className="label">{link.label}</Text>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
