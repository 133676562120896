import { Link, useNavigate } from "react-router-dom";

import {
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Group,
  Anchor,
  Center,
  Box,
  rem,
  PinInput,
  PasswordInput,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import "./styles.scss";
import { useForm } from "@mantine/form";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  validateEmail,
} from "../../../Lib/Methods";
import { PerformRequest } from "../../../Lib/usePerformRequest";
import { Endpoints } from "../../../Lib/Endpoints";
import { useState } from "react";

export function ForgotPassword() {
  const navigate = useNavigate();
  const emailForm = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) =>
        validateEmail(value) ? null : "Enter a valid email address",
    },
  });
  const passwordForm = useForm({
    initialValues: {
      otp: "",
      password: "",
      confirmPassword: "",
    },
    validate: {
      otp: (value) =>
        !value || value.length < 6 ? "Please enter your OTP" : null,
      password: (value) => (value.length === 0 ? "Enter a new password" : null),
      confirmPassword: (value, values) =>
        values.password === value ? null : "Your passwords do not match",
    },
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [screen, setScreen] = useState<"email" | "password">("email");
  const RequestOTP = async () => {
    const { hasErrors } = emailForm.validate();
    if (!hasErrors) {
      const { email } = emailForm.values;
      setLoading(true);
      const r = await PerformRequest({
        method: "POST",
        route: Endpoints.RequestOTP,
        data: {
          email,
        },
        callback: () => {
          setLoading(false);
        },
      });
      setLoading(false);
      if (r?.status === 200) {
        setScreen("password");
        DefaultSuccessNotification("OTP sent to your email!");
      }
      if (r?.status === 404) {
        DefaultErrorNotification("Account not found!");
      }
    }
  };
  const UpdatePassword = async () => {
    const { hasErrors } = passwordForm.validate();
    if (!hasErrors) {
      const { email } = emailForm.values;
      const { otp, password } = passwordForm.values;
      setLoading(true);
      const r = await PerformRequest({
        method: "POST",
        route: Endpoints.UpdatePassword,
        data: {
          email,
          code: otp,
          password,
        },
        callback: () => {
          setLoading(false);
        },
      });
      setLoading(false);
      if (r?.status === 200) {
        DefaultSuccessNotification("Your password has been changed!");
        navigate("/login");
      }
      if (r?.status === 404) {
        DefaultErrorNotification("Code is expired or invalid!");
      }
    }
  };
  return (
    <div className="forgot-password-container">
      <Title className="title" ta="center">
        Forgot your password?
      </Title>
      <Text c="dimmed" fz="sm" ta="center">
        {screen === "email"
          ? "Enter your email to get a reset link"
          : "Enter OTP and new password"}
      </Text>

      {screen === "email" ? (
        <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
          <TextInput
            disabled={isLoading}
            {...emailForm.getInputProps("email")}
            label="Your email"
            placeholder="me@futurefishagro.com"
            required
          />
          <Group justify="space-between" mt="lg" className="controls">
            <Anchor c="dimmed" size="sm" className="control">
              <Center inline>
                <IconArrowLeft
                  style={{ width: rem(12), height: rem(12) }}
                  stroke={1.5}
                />
                <Link to="/login">
                  <Box ml={5}>
                    <Text c="var(--blue-primary)">Login</Text>
                  </Box>
                </Link>
              </Center>
            </Anchor>
            <Button
              color="var(--blue-primary)"
              className="control"
              loading={isLoading}
              onClick={RequestOTP}
            >
              Request OTP
            </Button>
          </Group>
        </Paper>
      ) : (
        <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
          <div className="flex-col align-center justify-center width-100">
            <PinInput
              length={6}
              {...passwordForm.getInputProps("otp")}
              size="md"
              disabled={isLoading}
            />
            <div className="flex-row align-start-justify-start width-100">
              <Text
                fz="sm"
                className="resend"
                c="var(--blue-primary)"
                onClick={RequestOTP}
              >
                Resend Code
              </Text>
            </div>
          </div>
          <PasswordInput
            disabled={isLoading}
            {...passwordForm.getInputProps("password")}
            label="New Password"
            placeholder="Enter new password"
            required
            my={20}
          />
          <PasswordInput
            disabled={isLoading}
            {...passwordForm.getInputProps("confirmPassword")}
            label="Confirm Password"
            placeholder="Enter password again"
            required
          />
          <Button
            fullWidth
            mt={20}
            color="var(--blue-primary)"
            loading={isLoading}
            onClick={UpdatePassword}
          >
            Update Password
          </Button>
        </Paper>
      )}
    </div>
  );
}
